<template>
  <div class="login">
    <el-dialog
      v-model="dialogVisible"
      title=""
      width="30%"
      @close="handleClose"
    >
      <div class="login-card">
        <div class="title">欢迎登录</div>
        <div class="userName">
          <el-input v-model="userName" placeholder="用户名"/>
        </div>
        <div class="passWord">
          <el-input type="password" v-model="passWord" placeholder="密码"/>
        </div>
        <div class="verificationCode">
          <div class="left">
            <el-input v-model="verificationCode" placeholder="验证码"/>
          </div>
          <div class="right">
            <img v-if="requestCodeSuccess" style="margin-top: 2px;" :src="randCodeImage"
                 @click="handleChangeCheckCode"/>
            <img v-else style="margin-top: 2px;" src="../assets/checkCode.jpg" @click="handleChangeCheckCode"/>
          </div>
        </div>
        <div class="btn">
          <el-button class="loginBtn" type="success" @click="login">登录</el-button>
        </div>
      </div>
      <!--      <template #footer>-->
      <!--      <span class="dialog-footer">-->
      <!--        <el-button @click="dialogVisible = false">Cancel</el-button>-->
      <!--        <el-button type="primary" @click="dialogVisible = false">Confirm</el-button>-->
      <!--      </span>-->
      <!--      </template>-->
    </el-dialog>
  </div>
</template>

<script>
import {getCurrentInstance, defineComponent, onMounted, ref, inject} from 'vue'
import {ElMessage} from 'element-plus'
import dayjs from 'dayjs'
import {getAction, postAction} from '../api/index'

export default defineComponent({
  name: 'List',
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const dialogVisible = ref(null)
    const list = ref([])
    const pageNo = ref(1)
    const total = ref(0)
    const userName = ref('')
    const passWord = ref('')
    const verificationCode = ref('')
    const randCodeImage = ref(null)
    const handleClose = inject('closeLogin')
    const requestCodeSuccess = ref(false)
    const currdatetime = ref(null)
    const getCode = () => {
      currdatetime.value = new Date().getTime()
      getAction(`/yncy/sys/randomImage/${currdatetime.value}`).then(res => {
        if (res.success) {
          randCodeImage.value = res.result
          requestCodeSuccess.value = true
        } else {
          ElMessage({
            message: res.message,
            type: 'error'
          })
          requestCodeSuccess.value = false
        }
      }).catch(() => {
        requestCodeSuccess.value = false
      })
    }
    const handleChangeCheckCode = () => {
      getCode()
    }
    const login = () => {
      postAction('/yncy/sys/login', {
        username: userName.value,
        password: passWord.value,
        captcha: verificationCode.value,
        checkKey: currdatetime.value
      }).then(res => {
        console.log(res)
        if (res.success) {
          ElMessage({
            message: '登录成功',
            type: 'success'
          })
          window.localStorage.setItem('yncy_token', res.result.token)
          window.localStorage.setItem('yncy_userInfo',JSON.stringify(res.result.userInfo))
          handleClose()
        } else {
          getCode()
          ElMessage({
            message: res.message,
            type: 'error'
          })
        }
      }).catch((err) => {
        ElMessage({
          message: err.data.message,
          type: 'error'
        })
      })
    }
    onMounted(() => {
      getCode()
      dialogVisible.value = props.visible
    })

    return {
      list,
      pageNo,
      total,
      dialogVisible,
      verificationCode,
      passWord,
      userName,
      requestCodeSuccess,
      handleClose,
      randCodeImage,
      handleChangeCheckCode,
      login
    }
  }
})
</script>
<style scoped lang="scss">
/deep/ .el-dialog {
  width: 300px !important;
  margin-top: 200px !important;
}

/deep/ .el-dialog__body {
  padding: 10px 20px;
}

/deep/ .el-button--success {
  width: 100%;

  span {
    color: #FFFFFF;
  }
}

.loginBtn {
  color: #fff;
  background-color: rgb(7, 193, 96);
  border-color: rgb(7, 193, 96);
}

.loginBtn:hover,
.loginBtn:focus {
  background: rgba(7, 193, 96, 0.9);
  border-color: rgba(7, 193, 96, 0.9);
  color: rgb(7, 193, 96);
}

.login-card {
  .title {
    font-size: 18px;
    text-align: center;
  }

  .userName, .passWord, .verificationCode, .btn {
    margin-top: 10px;
  }

  .verificationCode {
    display: flex;
  }

  .btn {
    text-align: center;
  }
}
</style>
