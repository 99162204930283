import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/expertList', // 专家列表
    name: 'ExpertList',
    component: () => import(/* webpackChunkName: "ExpertList" */ '../views/ExpertList.vue')
  },
  {
    path: '/expert', // 专家详情
    name: 'Expert',
    component: () => import(/* webpackChunkName: "Expert" */ '../views/Expert.vue')
  },
  {
    path: '/knowledgeList', // 知识库列表
    name: 'KnowledgeList',
    component: () => import(/* webpackChunkName: "KnowledgeList" */ '../views/KnowledgeList.vue')
  },
  {
    path: '/knowledge', // 知识库详情
    name: 'Knowledge',
    component: () => import(/* webpackChunkName: "Knowledge" */ '../views/Knowledge.vue')
  },
  {
    path: '/industry', // 行业动态详情
    name: 'Industry',
    component: () => import(/* webpackChunkName: "Industry" */ '../views/Industry.vue')
  },
  {
    path: '/policy', // 政策
    name: 'Policy',
    component: () => import(/* webpackChunkName: "Policy" */ '../views/Policy.vue')
  },
  {
    path: '/policyList', // 政策
    name: 'PolicyList',
    component: () => import(/* webpackChunkName: "PolicyList" */ '../views/PolicyList.vue')
  },
  {
    path: '/standard', // 技术规范
    name: 'Standard',
    component: () => import(/* webpackChunkName: "Standard" */ '../views/Standard.vue')
  },
  {
    path: '/standardList', // 技术规范
    name: 'StandardList',
    component: () => import(/* webpackChunkName: "StandardList" */ '../views/StandardList.vue')
  },
  {
    path: '/noticeList', // 通知公告
    name: 'NoticeList',
    component: () => import(/* webpackChunkName: "NoticeList" */ '../views/NoticeList.vue')
  },
  {
    path: '/periodicalList', // 期刊列表
    name: 'PeriodicalList',
    component: () => import(/* webpackChunkName: "PeriodicalList" */ '../views/PeriodicalList.vue')
  },
  {
    path: '/periodical', // 期刊
    name: 'Periodical',
    component: () => import(/* webpackChunkName: "Periodical" */ '../views/Periodical.vue')
  },
  {
    path: '/notice', // 通知公告
    name: 'Notice',
    component: () => import(/* webpackChunkName: "Notice" */ '../views/Notice.vue')
  },
  {
    path: '/business', // 业务系统
    name: 'Business',
    component: () => import(/* webpackChunkName: "Business" */ '../views/Business.vue')
  },
  {
    path: '/downList', // 下载
    name: 'DownList',
    component: () => import(/* webpackChunkName: "DownList" */ '../views/DownList.vue')
  },
  {
    path: '/newsList', // 新闻列表
    name: 'NewsList',
    component: () => import(/* webpackChunkName: "NewsList" */ '../views/NewsList.vue')
  },
  {
    path: '/grassDetial', // 林草保护
    name: 'GrassDetial',
    component: () => import(/* webpackChunkName: "News" */ '../views/grassDetial.vue')
  },
  {
    path: '/news', // 新闻
    name: 'News',
    component: () => import(/* webpackChunkName: "News" */ '../views/News.vue')
  },
  {
    path: '/leadershipArea', // 领导专区
    name: 'leadershipArea',
    component: () => import(/* webpackChunkName: "News" */ '../views/leadershipArea.vue')
  },
  {
    path: '/textDetails', // 正文详情
    name: 'textDetails',
    component: () => import(/* webpackChunkName: "Policy" */ '../views/textDetails.vue')
  },
  {
    path: '/newsLists', // 新闻列表
    name: 'NewsLists',
    component: () => import(/* webpackChunkName: "NewsList" */ '../views/NewsLists.vue')
  },
  {
    path: '/opinion', // 意见征集
    name: 'opinion',
    component: () => import(/* webpackChunkName: "NewsList" */ '../views/opinionSolicitation.vue')
  },
  {
    path: '/video', // 行业视频
    name: 'video',
    component: () => import(/* webpackChunkName: "NewsList" */ '../views/industryVideo.vue')
  },
  {
    path: '/specialColumn', // 专题专栏
    name: 'specialColumn',
    component: () => import(/* webpackChunkName: "NewsList" */ '../views/specialColumn.vue')
  },
  {
    path: '/search', // 搜索
    name: 'search',
    component: () => import(/* webpackChunkName: "NewsList" */ '../views/search.vue')
  },
  {
    path: '/grass', // 林草分布
    name: 'Grass',
    component: () => import(/* webpackChunkName: "NewsList" */ '../views/grassDistribution.vue')
  },
  {
    path: '/messageBoard', // 留言板
    name: 'messageBoard',
    component: () => import(/* webpackChunkName: "NewsList" */ '../views/messageBoard.vue')
  },
  {
    path: '/messageBox', // 留言框
    name: 'messageBox',
    component: () => import(/* webpackChunkName: "NewsList" */ '../views/messageBox.vue')
  },
  {
    path: '/ApplicationGuide', // 留言框
    name: 'ApplicationGuide',
    component: () => import(/* webpackChunkName: "NewsList" */ '../views/ApplicationGuide.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path !== '/news'){
    document.title = '云南省林业和草原有害生物防治检疫局'
  }
  next()
})
router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
