import axios from 'axios'
import { ElMessage } from 'element-plus'

// 构建axios实例
// const instance = axios.create({
//   baseURL: process.env.BASE_API,// 该处url会根据开发环境进行变化（开发/发布）
//   timeout: 10000// 设置请求超时连接时间
// })
//
// instance.interceptors.request.use(
//   config => {
//     console.log(config)// 该处可以将config打印出来看一下，该部分将发送给后端（server端）
//     config.headers.token = '该处可设置token内容'
//     return config// 对config处理完后返回，下一步将向后端发送请求
//   },
//   error => {// 当发生错误时，执行该部分代码
//     console.log(error)// 调试用
//     return Promise.reject(error)
//   }
// )
//
// instance.interceptors.response.use(
//   response => {// 该处为后端返回整个内容
//     const res = response.data// 该处可将后端数据取出，提前做一个处理
//     if ('正常情况') {
//       return response// 该处将结果返回，下一步可用于前端页面渲染用
//     } else {
//       alert('该处异常')
//       return Promise.reject('error')
//     }
//   },
//   error => {
//     console.log(error)
//     return Promise.reject(error)
//   }
// )

const baseURL = 'http://ynljjhygl.dpitmo.com'
// const baseURL = 'http://192.168.31.109:7606/'

const service = axios.create({
  baseURL,
  withCredentials: true,
  timeout: 500000
})
service.interceptors.request.use(
  config => {
    config.headers['X-Access-Token'] = window.localStorage.getItem('yncy_token')
    return config
  },
  error => {
    console.log(Promise)
    return Promise.reject(error)
  }
)
service.interceptors.response.use(response => {
  const res = response.data
  if (res.code === 200 || res.success) {
    return res
  } else if (res.code === 500 && res.message === 'Token失效，请重新登录') {
    ElMessage({
      message: '请输入手机号',
      type: 'warning'
    })
    return res
  } else {
    return Promise.reject(res)
  }
}, error => {
  if (error.response){
    const data = error.response.data
    switch (error.response.status) {
      case 500:
        if (window.localStorage.getItem('yncy_token') && data.message =='Token失效，请重新登录'){
          window.localStorage.clear()
          location.reload()
          ElMessage({
            message: data.message,
            type: 'warning'
          })
        }
    }
  }
})
export default service
