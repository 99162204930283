import request from './request.ts'
import axios from 'axios'

// 新闻管理模块
export function yncyNewsList(data) {
  return request({
    url: '/yncy/news/yncyNews/list',
    method: 'GET',
    params: data
  })
}

// 新闻内容
export function yncyNews(data) {
  return request({
    url: '/yncy/news/yncyNews/queryById',
    method: 'GET',
    params: data
  })
}

// 行业动态
export function industryNews(data) {
  return request({
    url: '/yncy/industrynews/yncyIndustryNews/list',
    method: 'GET',
    params: data
  })
}

// 行业动态
export function industryNewsDetail(data) {
  return request({
    url: '/yncy/notice/yncyNotice/queryById',
    method: 'GET',
    params: data
  })
}

// 专家管理模块
export function yncyExpertList(data) {
  return request({
    url: '/yncy/expert/yncyExpert/list',
    method: 'GET',
    params: data
  })
}

// 专家详情
export function yncyExpert(data) {
  return request({
    url: '/yncy/expert/yncyExpert/queryById',
    method: 'GET',
    params: data
  })
}

// 行业列表模块
export function yncyIndustryNewsList(data) {
  return request({
    url: '/yncy/industrynews/yncyIndustryNews/list',
    method: 'GET',
    params: data
  })
}

// 行业体系列表模块
export function yncyIndustryList(data) {
  return request({
    url: '/yncy/industry/yncyIndustry/list',
    method: 'GET',
    params: data
  })
}

// 行业体系列表模块
export function yncyIndustryDetail(data) {
  return request({
    url: '/yncy/industry/yncyIndustry/queryById',
    method: 'GET',
    params: data
  })
}

// 政策列表模块
export function yncyPolciesList(data) {
  return request({
    url: '/yncy/polcies/yncyPolcies/list',
    method: 'GET',
    params: data
  })
}

// 政策信息模块
export function yncyPolciesDetail(data) {
  return request({
    url: '/yncy/polcies/yncyPolcies/queryById',
    method: 'GET',
    params: data
  })
}

// 技术标准模块
export function standardList(data) {
  return request({
    url: '/yncy/stand/yncyStand/list',
    method: 'GET',
    params: data
  })
}

// 技术标准模块
export function standardDetail(data) {
  return request({
    url: '/yncy/stand/yncyStand/queryById',
    method: 'GET',
    params: data
  })
}

// 通知公告列表
export function noticeList(data) {
  return request({
    url: '/yncy/notice/yncyNotice/list',
    method: 'GET',
    params: data
  })
}

// 通知公告详情
export function noticeDetail(data) {
  return request({
    url: '/yncy/notice/yncyNotice/queryById',
    method: 'GET',
    params: data
  })
}

// 知识列表模块
export function yncyKnowledgeList(data) {
  return request({
    url: '/yncy/knowledge/yncyKnowledge/list',
    method: 'GET',
    params: data
  })
}

// 行业知识库详情
export function knowledgeDetail(data) {
  return request({
    url: '/yncy/knowledge/yncyKnowledge/queryById',
    method: 'GET',
    params: data
  })
}

// 期刊列表模块
export function yncyPeriodicalList(data) {
  return request({
    url: '/yncy/periodical/yncyPeriodical/list',
    method: 'GET',
    params: data
  })
}

// 期刊模块
export function yncyPeriodical(data) {
  return request({
    url: '/yncy/periodical/yncyPeriodical/queryById',
    method: 'GET',
    params: data
  })
}

// 下载资料
export function yncyArchive(data) {
  return request({
    url: '/yncy/archive/yncyArchive/list',
    method: 'GET',
    params: data
  })
}

// 业务系统
export function yncySystem(data) {
  return request({
    url: '/yncy/system/yncySystem/list',
    method: 'GET',
    params: data
  })
}

// 领导列表
export function leaderList(data) {
  return request({
    url: '/yncy/industry/leadership/list',
    method: 'GET',
    params: data
  })
}

// 根据url获取正文详情
export function getTextDetails(url, data) {
  return request({
    url: url,
    method: 'GET',
    params: data
  })
}

// 意见列表
export function opinionList(data) {
  return request({
    url: '/yncy/interactive/opinion/list',
    method: 'GET',
    params: data
  })
}

// get请求
export function getAction(url, data) {
  return request({
    url: url,
    method: 'GET',
    params: data
  })
}

// post请求
// export function postAction (url, data) {
//   return request({
//     url: url,
//     method: 'POST',
//     params: data
//   })
// }
export function postAction(url, parameter) {
  return request({
    url: url,
    method: 'post',
    data: parameter
  })
}
