<template>
  <div style="background: #F5F5F5;padding-top: 10px">
    <!--      <div class="noticeintro w">-->
    <!--        <i class="noticeintro-icon"></i>-->
    <!--        <ul class="noticeintro-list">-->
    <!--          <li v-for="item of loopNews" :key="item.id">-->
    <!--            <router-link :to="{path: 'news', query: {id: item.id}}">-->
    <!--              · {{item.subject}}-->
    <!--            </router-link>-->
    <!--          </li>-->
    <!--        </ul>-->
    <!--        <div class="noticeintro-button"><router-link to="/newsList">更多>></router-link></div>-->
    <!--      </div>-->
    <div class="notice w">
      <div class="swiper">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item of loopNews" :key="item.id">
              <router-link :to="{path: 'news', query: {id: item.id,type:2,title:'要闻快讯'}}">
                <img style="width:100%;height: 100%" :src="item.rotateImgurl"/>
              </router-link>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
        <div class="swiper-info"><span>{{ swiperInfo }}</span></div>
      </div>
      <div style="width: 20px;height: 400px;background: #f5f5f5"></div>
      <div class="noticelist">
        <div class="titleAndMore">
          <div class=" leftTabs">
            <!--            <div style="margin-left: 20px" :class="{protectionActive:newsType ===1}" @click="newsTypeChange(1)">通知公告-->
            <!--            </div>-->
            <div style="margin-left: 20px" :class="{protectionActive:newsType ===2}" @click="newsTypeChange(2)">要闻快讯
            </div>
            <div :class="{protectionActive:newsType ===3}" @click="newsTypeChange(3)">行业动态</div>
          </div>
          <div class="rightMore">
            <router-link :to="{path:'/NewsList', query:{type:newsType}}">查看更多&nbsp;></router-link>
          </div>
        </div>
        <div class="noticelist-list">
          <div class="noticelist-list-item" v-for="item in news" @click="JumpDetails('News',item.id,newsType)">
            <div class="noticelist-list-item-title">{{
                item.subject.slice(0, 30)
              }}{{ item.subject.length > 30 ? '...' : '' }}
            </div>
            <div class="noticelist-list-item-time">{{ formartTime(item.createTime) }}</div>
          </div>
        </div>
        <!--        <ul class="list">-->
        <!--          <li v-for="item of news" :key="item.id">-->
        <!--            <router-link :to="{path: 'notice', query: {id: item.id}}">-->
        <!--              <i></i>{{ item.subject }}-->
        <!--            </router-link>-->
        <!--            <span class="list-day">2020-11-15</span>-->
        <!--          </li>-->
        <!--        </ul>-->
      </div>
    </div>
    <!--    业务平台-->
    <div class="syslist w">
      <div class="titleAndMore">
        <div class="leftTitle">业务平台</div>
      </div>
      <div class="syslist-box">
        <div class="syslist-box-item">
          <div class="syslist-box-item-logo">
            <div style="width: 100%;display: flex;justify-content: center">
            <img src="../assets/system1.png"/>
            </div>
            <div class="syslist-box-item-title">草原生物灾害监测预警系统</div>
<!--            <div class="syslist-box-item-introduction">“物联网虫害监控系统”是由“性诱测报系统”、“灯诱测报系统”、“高空灯诱系统”、“气候观测系统”等</div>-->
            <div class="syslist-box-item-entrance"><a href="http://iot.yunnanlinjian.com" target="_blank">进入系统</a>
              <i class="syslist-box-item-icon"></i>
            </div>
          </div>
        </div>
        <div class="syslist-box-item">
          <div class="syslist-box-item-logo">
            <div style="width: 100%;display: flex;justify-content: center">
              <img src="../assets/system2.png"/>
            </div>
            <div class="syslist-box-item-title">草原生物灾害地面普查系统</div>
<!--            <div class="syslist-box-item-introduction">由草原生物灾害地面监测调查APP及调查数据分析管理后台组成，APP包含草原病害、虫害、毒害草、啮齿动物四类有害等-->
<!--            </div>-->
            <div class="syslist-box-item-entrance"><a href="http://cypc.yunnanlinjian.com" target="_blank">进入系统</a>
              <i class="syslist-box-item-icon"></i>
            </div>
          </div>
        </div>
        <div class="syslist-box-item">
          <div class="syslist-box-item-logo">
            <div style="width: 100%;display: flex;justify-content: center">
              <img src="../assets/system3.png"/>
            </div>
            <div class="syslist-box-item-title">草原生物灾害防治及行业管理系统</div>
<!--            <div class="syslist-box-item-introduction">草原生物灾害防治行业基础管理功能包括机构管理、人员及防治队伍管理，专家支撑体系管理。草原生物灾害防治信息统计与管理功能等-->
<!--            </div>-->
            <div class="syslist-box-item-entrance"><a href="http://hygl.yunnanlinjian.com" target="_blank">进入系统</a>
              <i class="syslist-box-item-icon"></i>
            </div>
          </div>
        </div>
        <div class="syslist-box-item">
          <div class="syslist-box-item-logo">
            <div style="width: 100%;height:100px;display: flex;justify-content: center"><img src="../assets/system4.jpg"/></div>
            <div class="syslist-box-item-title">林草生物数字标本馆</div>
<!--            <div class="syslist-box-item-introduction">“物联网虫害监控系统”是由“性诱测报系统”、“灯诱测报系统”、“高空灯诱系统”、“气候观测系统”等</div>-->
            <div class="syslist-box-item-entrance"><a href="http://39.105.212.137:8070/fgo" target="_blank">进入系统</a>
              <i class="syslist-box-item-icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 云南省林业局和草原局   领导专区-->
    <div class="leaderShipArea w">
      <div class="leaderShipArea-left">
        <div class="titleAndMore">
          <div class="leftTitle">云南省林业局和草原局</div>
        </div>
        <div class="leaderShipArea-left-img">
          <img src="../assets/grassland.png"/>
        </div>
        <div class="leaderShipArea-left-introduction">
          <!--          由草原生物灾害地面监测调查APP及调查数据分析管理后台组成，APP包含草原病害、虫害、毒害草、啮齿动物四类有害生物调查与数据采集，符合国家相关调查标准，支持重大紧急及检疫性草原生物灾害报警、调查任务接收执行、APP端调查数据管理、远程诊断、消息接受等功能。调查数据分析管理后台包含调查任务管理、调查数据管理、调查标准规范设置、调查数据审核、四类生物灾害知识库、调查成果管理模块。-->
          云南省林业和草原局公开政府信息的范围，主要是涉及公民、法人或者其他组织切身利益、需要社会公众广泛知晓或参与的涉及全省林草工作的有关事项，以及法律、法规规定需要公开的其他事项。依法确定为国家秘密的政府信息，法律、行政法规禁止公开的政府信息，以及公开后可能危及国家安全、公共安全、经济安全、社会稳定的政府信息，不予公开。涉及商业秘密、个人隐私等公开会对第三方合法权益造成损害的政府信息，不予公开。第三方同意公开或者行政机关认为不公开会对公共利益造成重大影响的，予以公开。
        </div>
        <div class="leaderShipArea-left-more">&nbsp;</div>
        <div class="leaderShipArea-left-btn">行业体系<i class="leaderShipArea-left-icon"></i></div>
      </div>
      <div class="leaderShipArea-right">
        <div class="leaderShipArea-right-top">
          <div class="titleAndMore">
            <div class="leftTitle">领导专区</div>
            <div class="rightMore">
              <router-link to="/leadershipArea">查看更多&nbsp;></router-link>
            </div>
          </div>
          <div class="leaderShipArea-right-top-list">
            <!--            <div @mouseover="mouseOver($event,'leader')" @mouseleave="mouseLeave($event,'leader')"-->
            <div class="leaderShipArea-right-top-list-item"
                 v-for="item in leaders">
              <!--              <img :src="item.headPortrait" @click="infoClick(item.id,'leader')"/>-->
              <div class="leaderShipArea-right-top-list-item-userInfo">
                <div class="userInfo-name">{{ item.name }}</div>
                <div class="userInfo-position">云南省林业和草原有害生物防治检疫局</div>
                <div class="userInfo-position">{{ item.role.slice(17) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="leaderShipArea-right-bottom">
          <div class="titleAndMore">
            <div class="leftTitle">专家支持</div>
            <div class="rightMore">
              <router-link to="/expertList">查看更多&nbsp;></router-link>
            </div>
          </div>
          <div class="leaderShipArea-right-bottom-list">
            <div @mouseover="mouseOver($event,'expert')" @mouseleave="mouseLeave($event,'expert')"
                 class="leaderShipArea-right-bottom-list-item" v-for="item in expertList">
              <img :src="item.imgurl" @click="infoClick(item.id,'expert')"/>
              <div class="leaderShipArea-right-bottom-list-item-type">{{
                  getExportCategory(item.exportCategory)
                }}
              </div>
              <div class="leaderShipArea-right-bottom-list-item-userInfo">
                <div class="userInfo-name">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 便民服务    行业视频-->
    <div class="videoService w">
      <div class="videoService-left">
        <div class="titleAndMore">
          <div class="leftTitle">便民服务</div>
        </div>
        <div class="videoService-left-content">
          <div class="videoService-left-content-left">
            <div class="videoService-left-content-img">
              <router-link to="/downList">
                <img src="../assets/industryDocuments.png"/>
              </router-link>
            </div>
            <div class="videoService-left-content-title">行业文件下载</div>
          </div>
          <div class="videoService-left-content-center">
            <div class="videoService-left-content-img">
              <router-link to="/business">
                <img src="../assets/industrySystemDown.png"/>
              </router-link>
            </div>
            <div class="videoService-left-content-title">行业系统下载</div>
          </div>
          <div class="videoService-left-content-right">
            <div class="videoService-left-content-img">
              <router-link to="/ApplicationGuide">
                <img src="../assets/registrationGuide.png"/>
              </router-link>
            </div>
            <div class="videoService-left-content-title">办证指南</div>
          </div>
        </div>
      </div>
      <div class="videoService-right">
        <div class="titleAndMore">
          <div class="leftTitle">行业视频</div>
          <div class="rightMore">
            <router-link to="/video">查看更多&nbsp;></router-link>
          </div>
        </div>
        <div class="videoService-right-content">
          <div class="videoService-right-content-item" v-for="item in videoList">
            <img class="videoService-right-content-item-cover" :src="item.picturePath">
            <img class="videoService-right-content-item-player" src="../assets/player.png"
                 @click="play(item.videoPath)">
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <!--    专题专栏-->
    <div class="specialColumn w">
      <div class="titleAndMore">
        <div class="leftTitle">专题专栏</div>
        <div class="rightMore">
          <router-link to="/specialColumn">查看更多&nbsp;></router-link>
        </div>
      </div>
      <div class="specialColumn-list">
        <div class="specialColumn-list-item" v-for="item in specialList">
          <img :src="item.cover" @click="specialDetail(item.id)"/>
        </div>
      </div>
    </div>
    <!--    林草保护-->
    <div class="protection w">
      <div class="protection-left">
        <div class="titleAndMore">
          <div class="leftTitle">林草保护</div>
          <div class="rightMore">
            <router-link to="/specialColumn">查看更多&nbsp;></router-link>
          </div>
        </div>
        <div class="protection-left-map">
          <div style="width: 600px;height: 490px" id="amap"/>
        </div>
      </div>
      <div style="width: 10px;background: #f5f5f5"></div>
      <div class="protection-right">
        <div class="titleAndMore">
          <div class=" leftTabs">
            <div :class="{protectionActive:protectionCategory ===1}" @click="protectionChage(1)">林草概况</div>
            <div :class="{protectionActive:protectionCategory ===3}" @click="protectionChage(3)">监测预警</div>
            <div :class="{protectionActive:protectionCategory ===4}" @click="protectionChage(4)">灾害防治</div>
            <div :class="{protectionActive:protectionCategory ===5}" @click="protectionChage(5)">检疫执法</div>
            <div :class="{protectionActive:protectionCategory ===6}" @click="protectionChage(6)">药剂药械</div>
          </div>
          <div class="rightMore" @click="details">
            查看更多&nbsp;>
          </div>
        </div>
        <div v-if="protectionCategory===1" class="protection-right-content">
          <div class="protection-right-content-item" v-for="item in protectionList">
            <div class="protection-right-content-item-title">{{ item.subject }}</div>
            <div class="protection-right-content-item-content">
              <div class="protection-right-content-item-content-img">
                <img src="../assets/doem.png">
              </div>
              <div class="protection-right-content-item-content-text">
                {{ delHtmlTag(item.body) }}
                <br>
                <router-link :to="{path:'/grassDetial', query:{id:item.id}}">
                <span style="color: #00BB3D;display: inline-block;line-height: 26px;cursor: pointer">
                  查看详情&nbsp;
                  <img style="vertical-align:middle" src="../assets/more_arrow_green.png"/>
                </span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="protection-right-content-item" v-for="item in protectionList2">
            <div class="protection-right-content-item-title">{{ item.subject }}</div>
            <div class="protection-right-content-item-content">
              <div class="protection-right-content-item-content-img">
                <img src="../assets/doem.png">
              </div>
              <div class="protection-right-content-item-content-text">
                {{ delHtmlTag(item.body) }}
                <br>
                <router-link :to="{path:'/grassDetial', query:{id:item.id}}">
                <span style="color: #00BB3D;display: inline-block;line-height: 26px;cursor: pointer">
                  查看详情&nbsp;
                  <img style="vertical-align:middle" src="../assets/more_arrow_green.png"/>
                </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="protection-list">
          <div class="protection-list-item" v-for="item in protectionList" @click="JumpDetails('GrassDetial',item.id)">
            <div class="protection-list-item-title">{{
                item.subject.slice(0, 30)
              }}{{ item.subject.length > 30 ? '...' : '' }}
            </div>
            <div class="protection-list-item-time">{{ formartTime(item.createTime) }}</div>
          </div>
        </div>
      </div>
    </div>
    <!--  留言板  意见征集  -->
<!--    <div class="comment w">-->
<!--      <div class="comment-left">-->
<!--        <div class="titleAndMore">-->
<!--          <div class="leftTitle">意见征集</div>-->
<!--          <div class="rightMore">-->
<!--            <router-link to="/opinion">查看更多&nbsp;></router-link>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="comment-left-list">-->
<!--          <div class="comment-left-list-item" v-for="item in opinionData">-->
<!--            <div class="comment-left-list-item-title" @click="goOpinionDetail(item.id)">{{-->
<!--                item.type == 1 ? '【征求】' : '【结果】'-->
<!--              }}{{ item.subject.substring(0, 80) }}{{ item.subject.length > 80 ? '...' : '' }}-->
<!--            </div>-->
<!--            <div class="comment-left-list-item-time">{{ formartTime(item.createTime) }}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash;      <div class="comment-right">&ndash;&gt;-->
<!--      &lt;!&ndash;        <div class="titleAndMore">&ndash;&gt;-->
<!--      &lt;!&ndash;          <div class="leftTitle">留言互动</div>&ndash;&gt;-->
<!--      &lt;!&ndash;          <div class="rightMore">&ndash;&gt;-->
<!--      &lt;!&ndash;            <router-link to="/messageBoard">查看更多&nbsp;></router-link>&ndash;&gt;-->
<!--      &lt;!&ndash;          </div>&ndash;&gt;-->
<!--      &lt;!&ndash;        </div>&ndash;&gt;-->
<!--      &lt;!&ndash;        <div class="comment-right-list">&ndash;&gt;-->
<!--      &lt;!&ndash;          <div class="messageItem" v-for="item in messageList">&ndash;&gt;-->
<!--      &lt;!&ndash;            <div class="messageTitle">&ndash;&gt;-->
<!--      &lt;!&ndash;              <div>{{ item.subject.substring(0, 25) }}{{ item.subject.length > 25 ? '...' : '' }}</div>&ndash;&gt;-->
<!--      &lt;!&ndash;              <div>{{ formartTime(item.createTime) }}</div>&ndash;&gt;-->
<!--      &lt;!&ndash;            </div>&ndash;&gt;-->
<!--      &lt;!&ndash;            <div class="message">{{ item.body?item.body.substring(0, 30):'' }}{{ !item.body?'':item.body.length > 30 ? '...' : '' }}</div>&ndash;&gt;-->
<!--      &lt;!&ndash;            <div class="reply">&ndash;&gt;-->
<!--      &lt;!&ndash;              <div class="triangle"></div>&ndash;&gt;-->
<!--      &lt;!&ndash;              <span style="color: #07C160;">回复:</span>&ndash;&gt;-->
<!--      &lt;!&ndash;              {{ item.reply?item.reply.substring(0, 30):'' }}{{!item.reply?'':item.reply.length > 30 ? '...' : '' }}&ndash;&gt;-->
<!--      &lt;!&ndash;            </div>&ndash;&gt;-->
<!--      &lt;!&ndash;            <div>&ndash;&gt;-->
<!--      &lt;!&ndash;            </div>&ndash;&gt;-->
<!--      &lt;!&ndash;          </div>&ndash;&gt;-->
<!--      &lt;!&ndash;          <router-link to="/messageBox">&ndash;&gt;-->
<!--      &lt;!&ndash;            <div class="comment-right-list-btn">我要留言&nbsp;+</div>&ndash;&gt;-->
<!--      &lt;!&ndash;          </router-link>&ndash;&gt;-->
<!--      &lt;!&ndash;        </div>&ndash;&gt;-->
<!--      &lt;!&ndash;      </div>&ndash;&gt;-->
<!--    </div>-->
    <!--    友情链接-->
    <div class="friendship w">
      <div class="titleAndMore">
        <div class="leftTitle">友情链接</div>
      </div>
      <ul class="friendship-list">
        <li><a href="http://www.forestry.gov.cn/"><img src="../assets/periodical8.png"></a></li>
        <li><a href="https://www.bdpc.org.cn/"><img src="../assets/zglc.jpg"></a></li>
        <li><a href="http://www.moa.gov.cn/"><img src="../assets/periodical.png"></a></li>
        <li><a href="http://www.most.gov.cn/"><img src="../assets/periodical3.png"></a></li>
        <li><a href="http://www.nhc.gov.cn/"><img src="../assets/periodical2.png"></a></li>
        <li><a href="http://www.caf.ac.cn/"><img src="../assets/periodical6.png"></a></li>
        <li><a href="http://www.cas.cn/"><img src="../assets/periodical4.png"></a></li>
        <li><a href="http://www.caas.cn/"><img src="../assets/periodical7.jpg"></a></li>
        <li><a href="http://www.chinarodent.com/"><img src="../assets/periodical5.png"></a></li>
        <li><a href="http://lcj.yn.gov.cn/"><img src="../assets/ynsly.jpg"></a></li>
      </ul>
    </div>
    <div style="height: 20px;background: #f5f5f5"></div>
    <video-model v-if="videoShow" :visible="videoShow" :path="videoPath"></video-model>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, ref, nextTick, provide} from 'vue'
import Swiper, {Pagination, Navigation, Autoplay} from 'swiper'
import 'swiper/swiper-bundle.css'
import videoModel from "@/components/videoModel"
import {
  yncyNewsList,
  yncyExpertList,
  yncyKnowledgeList,
  yncyIndustryNewsList,
  yncyPeriodicalList,
  noticeList,
  leaderList,
  getAction,
  opinionList,
} from '../api/index'
import Expert from '../components/Expert'
import dayjs from 'dayjs'
import {useRoute, useRouter} from 'vue-router'
import echarts from 'echarts'
import pinyin from "../../node_modules/js-pinyin/index";

require('echarts/lib/chart/map')
require('echarts/map/js/china')
Swiper.use([Pagination, Navigation, Autoplay])

export default defineComponent({
  name: 'Home',
  components: {
    Expert,
    videoModel
  },
  setup() {
    const loopNews = reactive([])
    // 轮询信息
    const swiperInfo = ref('')
    const router = useRouter()
    const videoShow = ref(false)
    const videoPath = ref(null)
    const swiperInit = () => {
      // eslint-disable-next-line no-new
      new Swiper('.swiper-container', {
        autoplay: true,
        observer: true,
        observeParents: true,
        loop: true,
        pagination: {
          el: '.swiper-pagination'
        },
        on: {
          slideChange: function (ctx) {
            if (loopNews.length > 0) {
              swiperInfo.value = loopNews[ctx.realIndex].subject
            }
          }
        }
      })
    }

    // 循环信息
    const getLoopNews = () => {
      yncyNewsList({rotate: 1, pageSize: 3, auditStatus: 1}).then(res => {
        console.log(res)
        res.result.records.forEach(item => {
          loopNews.push(item)
        })
        console.log(loopNews)
        nextTick(() => {
          swiperInit()
        })
      })
    }

    // 通知公告
    const newsType = ref(2)
    const news = ref([])
    const newsUrl = ref(null)
    const getNews = () => {
      if (newsType.value === 1) {
        newsUrl.value = '/yncy/notice/yncyNotice/list'
      } else if (newsType.value === 2) {
        newsUrl.value = '/yncy/news/yncyNews/list'
      } else if (newsType.value === 3) {
        newsUrl.value = '/yncy/industrynews/yncyIndustryNews/list'
      }
      getAction(newsUrl.value, {pageSize: 7, auditStatus: 1}).then(res => {
        news.value = res.result.records
      })
    }
    const newsTypeChange = (type) => {
      newsType.value = type
      getNews()
    }

    // 获取省级市级数据
    const newsCategoryActive = ref(0)
    const newsCategory = ref([])
    const getNewsCategory = (category) => {
      newsCategoryActive.value = category

      let prs
      if (category === 1) {
        prs = Promise.all([yncyNewsList({pageSize: 6, newsCategory: 1, auditStatus: 1}), yncyNewsList({
          pageSize: 6,
          newsCategory: 2
        })])
      } else {
        prs = yncyNewsList({pageSize: 6, newsCategory: category, auditStatus: 1})
      }

      prs.then(res => {
        newsCategory.value = []
        if (category === 1) {
          const list = [...res[0].result.records, ...res[1].result.records]
          for (let i = 0; i < list.length; i++) {
            if (i > 5) {
              break
            }
            newsCategory.value.push(list[i])
          }
        } else {
          res.result.records.forEach(item => {
            newsCategory.value.push(item)
          })
        }
      })
    }

    // 获取专家
    const expertList = ref([])
    const getExpertList = () => {
      yncyExpertList({pageSize: 4}).then(res => {
        expertList.value = []
        res.result.records.forEach(item => {
          expertList.value.push(item)
        })
      })
    }
    const expertTabActive = ref(0)
    const expertTab = (val) => {
      expertTabActive.value = val
      getExpertList(val)
    }
    // 获取领导
    const leaders = ref([])
    const getleaderList = () => {
      leaderList({pageSize: 4}).then(res => {
        leaders.value = res.result.records
      })
    }

    // 获取知识库
    const knowledgeList = ref([])
    const getKnowledgeList = () => {
      yncyKnowledgeList({pageSize: 6}).then(res => {
        knowledgeList.value = []
        res.result.records.forEach(item => {
          knowledgeList.value.push(item)
        })
      })
    }

    // 获取行业动态
    const industryList = ref([])
    const getIndustryList = () => {
      yncyIndustryNewsList({pageSize: 6}).then(res => {
        industryList.value = []
        res.result.records.forEach(item => {
          industryList.value.push(item)
        })
      })
    }

    // 获取期刊列表
    const periodicalList = ref([])
    const getPeriodicalList = () => {
      yncyPeriodicalList({pageSize: 4}).then(res => {
        periodicalList.value = []
        res.result.records.forEach(item => {
          periodicalList.value.push(item)
        })
      })
    }
    // 获取专家类型
    const getExportCategory = (value) => {
      if (value === 0) return '害虫防治'
      if (value === 1) return '药剂药械'
      if (value === 2) return '监测预警'
      if (value === 3) return '鼠害防治'
      if (value === 4) return '病害防治'
      if (value === 5) return '毒害草防治'
    }
    const mouseOver = (e, type) => {
      if (type === 'leader') {
        e.target.parentNode.children[1].style.backgroundColor = '#00BB3D'
        e.target.parentElement.children[1].children[0].style.color = '#fff'
        e.target.parentElement.children[1].children[1].style.color = '#fff'
      } else if (type === 'expert') {
        e.target.parentNode.children[2].style.backgroundColor = '#00BB3D'
        e.target.parentNode.children[2].children[0].style.color = '#fff'
      }
    }
    const mouseLeave = (e, type) => {
      if (type === 'leader') {
        e.target.children[1].style.backgroundColor = '#fff'
        e.target.children[1].children[0].style.color = '#000'
        e.target.children[1].children[1].style.color = 'rgba(0, 0, 0, 0.45)'
      } else if (type === 'expert') {
        e.target.children[2].style.backgroundColor = '#fff'
        e.target.children[2].children[0].style.color = '#000'
      }
    }
    // 进入专家详情
    const infoClick = (id, type) => {
      if (type === 'leader') {
        router.push({
          name: 'leadershipArea',
          query: {
            id: id
          }
        })
      } else if (type === 'expert') {
        router.push({
          name: 'Expert',
          query: {
            id: id
          }
        })
      }
    }
    // 获取行业视频
    const videoList = ref([])
    const getVideoList = () => {
      getAction('/yncy/video/list', {pageNo: 1, pageSize: 3}).then(res => {
        videoList.value = res.result.records
      })
    }

    // 获取4条专题专栏
    const specialList = ref([])
    const getSpecialList = () => {
      getAction('/yncy/column/list', {pageSize: 4, pageNo: 1}).then(res => {
        console.log(res)
        specialList.value = res.result.records
      })
    }
    // 林草保护
    const protectionCategory = ref(1)

    const protectionList = ref([])
    const getProtectionList = () => {
      getAction('/yncy/grassProtect/list', {
        pageSize: protectionCategory.value === 1 ? 2 : 7,
        pageNo: 1,
        category: protectionCategory.value
      }).then(res => {
        protectionList.value = res.result.records
      })
    }
    const protectionList2 = ref([])
    const getProtectionList2 = () => {
      getAction('/yncy/grassProtect/list', {
        pageSize: 1,
        pageNo: 1,
        category: 2
      }).then(res => {
        protectionList2.value = res.result.records
      })
    }
    const protectionChage = (type) => {
      protectionCategory.value = type
      getProtectionList()
      getProtectionList2()
    }
    // 处理文本
    const delHtmlTag = (str) => {
      return str.replace(/<[^>]+>/g, '').replace(/(&.*?;){1,}/ig, '').substring(0, 120)
    }
    // 意见征集
    const opinionData = ref([])
    const getOpinionList = () => {
      opinionList({pageSize: 7, pageNo: 1}).then(res => {
        opinionData.value = res.result.records
      })
    }
// 获取留言
    const messageList = ref([])
    const getMesssageList = () => {
      getAction('/yncy/interactive/leaveMessage/list', {pageSize: 2, pageNo: 1}).then(res => {
        messageList.value = res.result.records
      })
    }
    /**
     *
     * @param urlName  跳转路径
     * @param id   所需id
     * @constructor
     */
    const JumpDetails = (urlName, id, type) => {
      router.push({
        name: urlName,
        query: {
          id: id,
          type: type,
          title: type === 1 ? '通知公告' : type === 2 ? '要闻快讯' : type === 3 ? '行业动态' : ''
        }
      })
    }
    const city = ref(null)
    const mapChart = ref(null)
    const initMap = (name) => {
      if (!name) {
        city.value = '云南'
        require('echarts/map/js/province/' + pinyin.getFullChars(city.value).toLowerCase() + '.js')
      } else {
        city.value = name
      }
      mapChart.value = echarts.init(document.getElementById('amap'))
      mapChart.value.setOption({
        visualMap: {
          left: 'left',
          min: 0,
          max: 5,
          pieces: [
            {min: 3000, label: '≥3000h㎡'}, // 不指定 max，表示 max 为无限大（Infinity）。
            {min: 2000, max: 2999, label: '2000~2999h㎡'},
            {min: 1000, max: 1999, label: '1000~1999h㎡'},
            {min: 100, max: 999, label: '100~999h㎡'},
            {min: 1, max: 99, label: '1~99h㎡'}, // 不指定 min，表示 min 为无限大（-Infinity）。
            {max: 0, label: '0h㎡'} // 不指定 min，表示
          ],
          inRange: {
            color: ['#F1FBFF', '#3AA1FF']
          },
          type: 'piecewise',
          calculable: true
        },
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          formatter: function (params) {
            if (isNaN(params.value)) {
              params.value = 0
            }
            return params.name + ': ' + params.value + 'hm²'
          }
        },
        series: [
          {
            type: 'map',
            map: city.value,

            itemStyle: {
              // 地图背景色
              normal: {
                areaColor: '#F1FBFF',
                borderColor: '#0B6ACC'
              }
            },
            data: mapData._rawValue
          }]
      }, true)
      mapChart.value.resize()
      mapChart.value.on('click', function (params) {
        cityCodeList.value.forEach(item => {
          if (item.name == params.name.replace('自治州', '').replace('市', '')) {
            getCityMapJson(params.name, item.dcode)
            getTableMap(params.name)
          }
        })
      })// 点
    }
    const mapList = ref(null)
    const getMapList = () => {
      getAction('/yncy/greedLandArea/grassDistribution', {
        province: '云南'
      }).then(res => {
        console.log(res)
      })
    }

    // 视频播放
    const play = (path) => {
      videoShow.value = true
      videoPath.value = path
    }
    // 关闭视频播放窗口
    const closeVideo = () => {
      videoShow.value = false
    }
    const mapData = ref([])
    const getTableMap = (cityName) => {
      getAction('/yncy/greedLandArea/grassDistribution', {
        province: '云南省', city: cityName ? cityName : null
      }).then(res => {
        res.result.distribution.forEach(item => {
          mapData.value.push({name: item.area, value: item.prairieArea});
        })
        initMap(cityName)
      })
    }
    // 专题专栏
    const specialDetail = (id) => {
      router.push({
        name: 'NewsLists',
        query: {
          url: '/yncy/columnList/list',
          pid: id,
          titleOne: '专题专栏',
          titleTwo: '专题列表'
        }
      })
      // {path:'/NewsLists', query:{url:'/yncy/grassProtect/list',category:4}}
    }
    const goOpinionDetail = (id) => {
      router.push({
        name: 'GrassDetial',
        query: {
          type: '意见征集',
          id: id
        }
      })
    }

    provide('closeVideo', closeVideo)
    onMounted(() => {
      getNews()
      getLoopNews()
      getNewsCategory(0)
      getExpertList(0)
      getKnowledgeList()
      getIndustryList()
      getPeriodicalList()
      getleaderList()
      getVideoList()
      getSpecialList()
      getProtectionList()
      getProtectionList2()
      getOpinionList()
      getMesssageList()
      getTableMap()
    })

    const formartTime = (val) => {
      return dayjs(val).format('YYYY-MM-DD')
    }

    const details = () => {
      if (protectionCategory.value === 1) {
        router.push({
          name: 'textDetails',
          query: {
            url: '/yncy/grassProtect/list',
            category: 1,
            titleOne: '林草保护',
            titleTwo: '林草概况'
          }
        })
      } else if (protectionCategory.value === 3) {
        router.push({
          name: 'NewsLists',
          query: {
            url: '/yncy/grassProtect/list',
            category: 3,
            titleOne: '林草保护',
            titleTwo: '监测预警'
          }
        })
      } else if (protectionCategory.value === 4) {
        router.push({
          name: 'NewsLists',
          query: {
            url: '/yncy/grassProtect/list',
            category: 4,
            titleOne: '林草保护',
            titleTwo: '灾害防治'
          }
        })
      } else if (protectionCategory.value === 5) {
        router.push({
          name: 'NewsLists',
          query: {
            url: '/yncy/grassProtect/list',
            category: 5,
            titleOne: '林草保护',
            titleTwo: '检疫执法'
          }
        })
      } else if (protectionCategory.value === 6) {
        router.push({
          name: 'NewsLists',
          query: {
            url: '/yncy/grassProtect/list',
            category: 5,
            titleOne: '林草保护',
            titleTwo: '药剂药械'
          }
        })
      }
    }
    return {
      swiperInfo,
      expertTabActive,
      expertTab,
      expertList,
      leaders,
      news,
      loopNews,
      newsCategory,
      getNewsCategory,
      newsCategoryActive,
      knowledgeList,
      industryList,
      periodicalList,
      formartTime,
      getExportCategory,
      mouseOver,
      mouseLeave,
      infoClick,
      videoList,
      specialList,
      protectionCategory,
      protectionChage,
      protectionList,
      protectionList2,
      delHtmlTag,
      opinionData,
      newsTypeChange,
      newsType,
      JumpDetails,
      messageList,
      videoShow,
      videoPath,
      play,
      details,
      specialDetail,
      goOpinionDetail
    }
  }
})
</script>

<style lang="scss" scoped>
.protectionActive {
  border-bottom: 2px solid #00BB3D;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.titleAndMore {
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E9E9E9;

  .leftTitle {
    padding: 20px;
    font-size: 20px;
    font-weight: 500;
    color: #000000;
  }

  .leftTabs {
    display: flex;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;

    div {
      height: 74px;
      line-height: 74px;
      width: 80px;
      text-align: center;
    }
  }

  .rightMore {
    cursor: pointer;
    font-size: 16px;
    padding: 20px;
    font-weight: 600;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(0, 0, 0, 0.45);

    a {
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.45);

      &:hover {
        color: #00BB3D;
      }
    }

    &:hover {
      color: #00BB3D;
    }
  }
}

.syslist {
  margin-top: 20px;
  height: 305px;
  background: #FFFFFF;

  .syslist-box {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    .syslist-box-item {
      width: 289px;
      //height: 264px;
      //background: #07C160;
      .syslist-box-item-title {
        margin-top: 20px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
      }

      .syslist-box-item-introduction {
        color: #555;
        padding: 0 20px;
        font-size: 14px;
        text-align: center;
      }

      .syslist-box-item-entrance {
        position: relative;
        margin-top: 20px;
        margin-bottom: 24px;
        text-align: center;
        font-weight: 500;
        font-size: 14px;

        a {
          color: #00BB3D;
        }

        .syslist-box-item-icon {
          position: absolute;
          right: 90px;
          top: 3px;
          display: block;
          background: url(../assets/triangle.png) no-repeat;
          background-size: 10px 10px;
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
    }
  }
}

.leaderShipArea {
  background: #F5F5F5;
  margin-top: 20px;
  height: 722px;
  display: flex;
  justify-content: space-between;

  .leaderShipArea-left {
    width: 386px;
    height: 722px;
    background: #FFFFFF;

    .leaderShipArea-left-img {
      width: 346px;
      margin: 24px 20px;
    }

    .leaderShipArea-left-introduction {
      font-size: 14px;
      line-height: 26px;
      width: 346px;
      margin: 0 20px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
    }

    .leaderShipArea-left-more {
      width: 346px;
      margin: 5px 0px 0px 20px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #00BB3D;
      line-height: 24px;
    }

    .leaderShipArea-left-btn {
      position: relative;
      width: 258px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      border-radius: 4px;
      margin: 0 auto;
      border: 1px solid #00BB3D;
      font-weight: 600;
      color: #00BB3D;
      cursor: pointer;

      .leaderShipArea-left-icon {
        position: absolute;
        right: 70px;
        top: 13px;
        display: block;
        background: url(../assets/triangle.png) no-repeat;
        background-size: 12px 12px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .leaderShipArea-right {
    width: 794px;
    height: 722px;

    .leaderShipArea-right-top {
      height: 351px;
      background: #FFFFFF;

      .leaderShipArea-right-top-list {
        width: 740px;
        //height: 226px;
        margin: 24px 19px 24px 20px;
        display: flex;

        .leaderShipArea-right-top-list-item {
          cursor: pointer;
          margin-left: 25px;
          position: relative;
          width: 220px;
          height: 226px;

          //img {
          //  width: 100%;
          //  height: 100%;
          //}

          .leaderShipArea-right-top-list-item-userInfo {
            //position: absolute;
            left: 0px;
            bottom: 50%;
            width: 100%;
            height: 62px;
            background: #FFFFFF;
            opacity: 0.85;
            pointer-events: none;

            .userInfo-name {
              width: 100%;
              height: 22px;
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: rgba(0, 0, 0, 0.85);
              line-height: 22px;
              margin-bottom: 10px;
            }

            .userInfo-position {
              margin-bottom: 10px;
              width: 100%;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.45);
            }
          }
        }
      }
    }

    .leaderShipArea-right-bottom {
      margin-top: 20px;
      height: 351px;
      background: #FFFFFF;

      .leaderShipArea-right-bottom-list {
        width: 740px;
        height: 226px;
        margin: 24px 19px 24px 20px;
        display: flex;

        .leaderShipArea-right-bottom-list-item {
          cursor: pointer;
          position: relative;
          margin-left: 15px;
          width: 170px;
          height: 226px;

          img {
            width: 100%;
            height: 100%;
          }

          .leaderShipArea-right-bottom-list-item-type {
            position: absolute;
            top: 0;
            right: 0;
            width: 70px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            background: #00BB3D;
            border-radius: 0px 0px 0px 8px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.85);
          }

          .leaderShipArea-right-bottom-list-item-userInfo {
            position: absolute;
            left: 11px;
            bottom: 10px;
            width: 149px;
            height: 38px;
            background: #FFFFFF;
            opacity: 0.85;

            .userInfo-name {
              text-align: center;
              width: 149px;
              height: 38px;
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: rgba(0, 0, 0, 0.85);
              line-height: 38px;
            }
          }
        }
      }
    }
  }
}

.videoService {
  margin: 0 auto;
  margin-top: 20px;
  height: 300px;
  display: flex;
  justify-content: space-between;

  .videoService-left {
    width: 386px;
    height: 300px;
    background: #FFFFFF;

    .videoService-left-content {
      width: 100%;
      height: 225px;
      display: flex;
      align-items: center;

      .videoService-left-content-img {
        width: 78px;
        height: 78px;
        margin: 0 auto;
      }

      .videoService-left-content-title {
        margin-top: 20px;
        height: 22px;
        line-height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 400;
        text-align: center;
      }

      .videoService-left-content-left {
        width: 33.3%;
        height: 107px;
      }

      .videoService-left-content-center {
        width: 33.3%;
        height: 107px;
      }

      .videoService-left-content-right {
        width: 33.3%;
        height: 107px;
      }
    }
  }

  .videoService-right {
    width: 794px;
    height: 300px;
    background: #FFFFFF;

    .videoService-right-content {
      margin: 24px 24px 0px 4px;
      //background: #00BB3D;
      height: 176px;
      display: flex;

      .videoService-right-content-item {
        position: relative;
        width: 235px;
        height: 176px;
        margin-left: 20px;

        .videoService-right-content-item-cover {
          width: 100%;
          height: 140px;
        }

        .videoService-right-content-item-player {
          position: absolute;
          cursor: pointer;
          left: 45%;
          top: 35%;
          width: 40px;
          height: 40px;
          z-index: 66;
        }
      }
    }
  }
}

.specialColumn {
  padding-bottom: 20px;
  margin: 20px auto 0 auto;
  background: #FFFFFF;

  .specialColumn-list {
    display: flex;
    height: 108px;
    margin-top: 24px;
    align-items: center;

    .specialColumn-list-item {
      width: 276px;
      height: 108px;
      margin-left: 19px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.protection {
  margin-top: 20px;
  height: 565px;
  background: #FFFFFF;
  display: flex;

  .protection-left {
    width: 540px;
    height: 565px;
  }

  .protection-right {
    padding-left: 10px;
    width: 650px;
    height: 565px;

    .protection-right-content {
      height: 490px;

      .protection-right-content-item {
        height: 224px;

        .protection-right-content-item-title {
          margin-top: 21px;
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          line-height: 22px;
        }

        .protection-right-content-item-content {
          margin-top: 16px;
          height: 165px;
          display: flex;

          .protection-right-content-item-content-img {
            width: 220px;
            height: 165px;
          }

          .protection-right-content-item-content-text {
            width: 515px;
            height: 156px;
            margin: 0 15px 0px 24px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            line-height: 26px;
          }
        }
      }

    }

    .protection-list {
      .protection-list-item {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:hover > div {
          color: #07C160;
        }

        .protection-list-item-title {
          margin-left: 20px;
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 22px;
        }

        .protection-list-item-time {
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          line-height: 22px;
          margin-right: 20px;
        }
      }
    }
  }
}

.comment {
  margin: 20px auto 0 auto;
  height: 400px;
  display: flex;

  .comment-left {
    width: 100%;
    height: 400px;
    background: #FFFFFF;

    .comment-left-list {
      width: 100%;

      .comment-left-list-item {
        height: 44px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:hover > div {
          color: #07C160;
        }

        .comment-left-list-item-title {
          margin-left: 20px;
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 22px;
        }

        .comment-left-list-item-time {
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          line-height: 22px;
          margin-right: 20px;
        }
      }
    }
  }

  .comment-right {
    width: 590px;
    height: 400px;
    background: #FFFFFF;
    margin-left: 20px;

    .comment-right-list {
      padding: 0 20px 0 20px;

      .messageItem {
        margin-top: 10px;

        .messageTitle {
          display: flex;
          justify-content: space-between;

          div:first-child {
            height: 24px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
          }

          div:last-child {
            height: 20px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);
            line-height: 20px;
          }
        }

        .message {
          margin-top: 8px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 22px;
        }

        .reply {
          margin-top: 12px;
          background: #F5F5F5;
          padding: 10px 5px;
          position: relative;
          border-radius: 5px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.65);
          line-height: 24px;

          .triangle {
            position: absolute;
            top: -10px;
            left: 10px;
            width: 0;
            height: 0;
            border-bottom: 10px solid #F5F5F5;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
          }
        }
      }

      .comment-right-list-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #00BB3D;
        margin: 0 auto;
        width: 258px;
        height: 38px;
        border-radius: 4px;
        border: 1px solid #00BB3D;
        margin-top: 20px;
      }
    }
  }
}

.friendship {
  height: 257px;
  margin: 20px auto 0 auto;
  background: #FFFFFF;

  .friendship-list {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 215px;
      height: 56px;
      margin-left: 21px;
      margin-top: 24px;
      overflow: hidden;

      &:hover {
        transform: scale(1.1);
        transition: all 0.3s;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.notice {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;

  .swiper-container {
    width: 600px;
    height: 400px;
  }

  .noticelist {
    width: 580px;

    .noticelist-list {
      .noticelist-list-item {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:hover > div {
          color: #07C160;
        }

        .noticelist-list-item-title {
          margin-left: 20px;
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 22px;
        }

        .noticelist-list-item-time {
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          line-height: 22px;
          margin-right: 20px;
        }
      }
    }
  }
}

.list {
  li.list-item {
    a {
      width: 290px;
    }
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    font-size: 16px;
    border-bottom: 1px dotted #DDDDDD;

    i {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #333333;
      margin-right: 4px;
    }

    a {
      color: #333333;
      display: block;
      width: 400px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;

      &:hover {
        color: #07C160;
      }
    }
  }

  &-day {
    color: #999999;
  }
}

.swiper {
  position: relative;

  img {
    width: 100%;
  }

  &-info {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 32px;
    line-height: 32px;
    padding: 0 10px;
    background: rgba(0, 0, 0, 0.2);
    z-index: 2;

    span {
      display: block;
      width: 450px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      color: #ffffff;
    }
  }
}
</style>
<style>
.swiper-container {
  --swiper-pagination-color: #ffffff; /* 两种都可以 */
}

.swiper-pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
</style>
