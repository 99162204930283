<template>
<div class="expert">
  <router-link :to="{path: '/expert', query: {id: item.id}}">
    <div class="expertimg">
      <img :src="item.imgurl" />
    </div>
    <div class="expertinfo">
      <div class="expertname">
        {{item.name}}
      </div>
      <div class="expertintro">
        {{item.summary}}
      </div>
    </div>
  </router-link>
</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Expert',
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.expert{
  width: 280px;
  height: 360px;
  position: relative;
}

.expertinfo{
  position: absolute;
  bottom: 0;
  left:0;
  width: 100%;
  padding: 12px;
  height: 100px;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
}
.expertimg{
  img{
    width: 280px;
    height: 360px;
  }
}
.expertname{
  font-size: 18px;
  color: #ffffff;
  padding-bottom: 6px;
}
.expertintro{
  font-size: 14px;
  color: #ffffff;
  line-height: 18px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
